<template>
  <main>
    <section class="floating-card bg-dark-blue">
      <h4 class="d-block font-weight-bold text-center text-white">title</h4>
      <p class="mb-0 d-block text-center text-white">
        text <strong>text</strong>
      </p>
      <div class="card my-3 p-3 text-center">MAP</div>
    </section>
    <section class="py-4">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-start align-items-start border-0"
              >
                <span class="badge mr-2">
                  <img src="@/assets/icons/icon_marker.svg" alt="Map Marker" />
                </span>
                <p class="mb-0">
                  <strong class="d-block mb-2">text</strong>
                  <span class="d-block mb-2">text</span>
                  <a href="" class="d-block">
                    <u>
                      <strong> link </strong>
                    </u>
                  </a>
                </p>
              </li>
              <li
                class="list-group-item d-flex justify-content-start align-items-start border-0"
              >
                <span class="badge mr-2">
                  <img
                    src="@/assets/icons/icon_calendar.svg"
                    alt="Map Marker"
                  />
                </span>
                <p class="mb-0">text</p>
              </li>
              <li
                class="list-group-item d-flex justify-content-start align-items-start border-0"
              >
                <span class="badge mr-2">
                  <img src="@/assets/icons/icon_phone.svg" alt="Map Marker" />
                </span>
                <p class="mb-0">
                  <span class="d-block mb-2">text</span>
                  <strong>text:</strong> text
                </p>
              </li>
            </ul>
            <button class="btn btn-primary btn-lg w-100">button</button>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light-gray py-4">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4 class="font-weight-bold d-block text-center mb-3">title</h4>
            <ol class="numbered-list">
              <li><span>text</span></li>
              <li><span>text</span></li>
              <li><span>text</span></li>
              <li><span>text</span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4 class="d-block text-center text-blue font-weight-bold">
              title
            </h4>
            <ul class="blue-tick-list">
              <li><strong>text</strong></li>
              <li><strong>text</strong></li>
              <li><strong>text</strong></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {};
</script>
